import React, { createRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import lottie from 'lottie-web';
import { graphql } from "gatsby";
import bowser from 'bowser';
import Records from "../assets/Tech-Log/tl-icon-validated-records.svg";
import Productiviy from "../assets/Tech-Log/tl-icon-productivity.svg";
import Investment from "../assets/Tech-Log/tl-icon-investment.svg";
import CAMP from "../assets/camp-logo@2x.png";
import AMOS from "../assets/amos_logo.png";
import RocketRoute from "../assets/rocketroute_logo.png";
import Centrik from "../assets/centrik_logo.png";
import FleetStatus from "../assets/Tech-Log/tl-fleet-status-dashboard.svg";
import History from "../assets/Tech-Log/tl-aircraft-history.svg";
import Validation from "../assets/Tech-Log/tl-data-validation.svg";
import Regulators from "../assets/Tech-Log/tl-regulators.svg"
import TechCard from "../components/TechLog/techCard";
import Tick from "../assets/icon-tick.svg";
import TLDashSafariAnimation from '../assets/TLDashSafari.json';
import TLDashAnimation from '../assets/TLDashAll.json';
import PostflightAnimation from '../assets/postflight.json';
import Started from "../components/Layout/started";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Seamless from "../components/Layout/seamlessIntegration";
import pageStyles from "./page.module.less";
import styles from "./taskcards.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";
import { Divider } from "antd";

const FleetStatusCard = {
  img: FleetStatus,
  title: "Fleet Status Dashboard",
  text:
    "With a clear overview of your entire fleet, you'll know where your aircraft are and see upcoming maintenance."
};

const HistoryCard = {
  img: History,
  title: "Full Aircraft History",
  text:
    "Instantly available within the app, even when offline, or in the ops dashboard. The full history of the aircraft is always where you need it."
};

const ValidationCard = {
  img: Validation,
  title: "Smart Data Validation",
  text:
    "Data entered by pilots and engineers is validated instantly. Notifications and records are immediately dispatched to your ops team on trip close-out."
};

const cardsArray = [FleetStatusCard, HistoryCard, ValidationCard];

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const TextWrapper = styled.div`
  @media screen and (max-width: 770px) {
    margin: 0 30px;
  }
  @media screen and (max-width: 450px) {
    margin: 0 20px;
  }
`;

const TickWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  width: 300px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: #242d41;
  margin-bottom: 40px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
  margin-bottom: 20px;
`;

const TickSection = styled.div`
  display: flex;
  margin-bottom: 12px;
  font-size: 15px;
  letter-spacing: 0.02px;
  color: #242d41;
  line-height: 1.75;
`;

const GradientBackground = styled.div`
  padding-bottom: 100px;
  background-image: linear-gradient(to top, #F8FCFF, #fff);
`;

const RegulatorImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 770px) {
    img {
      width: 50%;
    }
  }
  @media screen and (max-width: 450px) {
    img {
      width: 100%;
    }
  }
`;

export default props => {
  const techlogAnimationRef = createRef();
  const postflightAnimationRef = createRef();

  const cards = cardsArray.map((card, key) => (
    <Col md={4} className={`${styles.techCardCol} ${pageStyles.coliPad}`}>
      <ScrollAnimation
        animateOnce
        animateIn={
          key === 0 ? "fadeInLeft" : key === 1 ? "fadeInUp" : "fadeInRight"
        }
        offset={0}
      >
        <TechCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
        />
      </ScrollAnimation>
    </Col>
  ));

  const { mainImage, paperFreeImg, benefitsImg } = props.data;

  const seamlessCards = [
    {
      img: RocketRoute,
      title: "RocketRoute"
    },
    {
      img: AMOS,
      title: "AMOS"
    },
    {
      img: Centrik,
      title: "Centrik"
    },
    {
      img: CAMP,
      title: "CAMP"
    }
  ];

  useEffect(() => {
    const safari = bowser.name === 'Safari';
    console.log(safari);
    const TLAnimation = lottie.loadAnimation({
      container: techlogAnimationRef.current,
      animationData: safari ? TLDashSafariAnimation : TLDashAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    const PostflightAnim = lottie.loadAnimation({
      container: postflightAnimationRef.current,
      animationData: PostflightAnimation,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <Layout>
      <SEO
        title="The Aircraft Tech Log"
        description="Make the final step to paperless operations"
      />
      <PageHeader
        header="The Aircraft Tech Log. <br /><span>Modernised.</span>"
        subHeader="Make the final step to paperless operations."
        linkText="Schedule a demo"
        bigHeader
        bigImage={mainImage.childImageSharp.fluid}
        imageWithShadow
        link="/contact?demo"
        whiteLink
        tag="Software for the Flight Deck"
        tagStrong="TECH LOG"
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={10}>
            <Row>
              <Col>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <h3 className={styles.title}>We will help you achieve</h3>
                </ScrollAnimation>
              </Col>
            </Row>
            <Row className={styles.topCardsParent}>
              <Col sm={4}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <div className={styles.topCardWrapper}>
                    <img src={Records} alt="records" />
                    <span>
                      Validated records, <br />
                      correct first time
                    </span>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col sm={4}>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <div className={styles.topCardWrapper}>
                    <img src={Productiviy} alt="productivity" />
                    <span>
                      Productivity increases from <br />
                      time saved
                    </span>
                  </div>
                </ScrollAnimation>
              </Col>
              <Col sm={4}>
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <div className={styles.topCardWrapper}>
                    <img src={Investment} alt="investment" />
                    <span>
                      An incredible return on <br />
                      investment
                    </span>
                  </div>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <h3 className={styles.title}>Paper Free. Error Free.</h3>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
              <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
                <CenteredDiv>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <h4 className={styles.subTitle}>
                      Reduce costly paper logs and eliminate transcription
                      errors. Our intelligent validation catches mistakes before
                      records are completed, ensures crew compliance, and
                      automates away repetitive data entry.
                    </h4>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
            </Row>
            <Row>
              <Col lg={7} md={6} className={pageStyles.coliPad}>
                <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                  <div
                    ref={techlogAnimationRef}
                  />
                </ScrollAnimation>
              </Col>
              <Col lg={4} md={5} className={`${pageStyles.coliPad} ${styles.margin50}`}>
                <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                  <p className={styles.description}>
                    This ensures that your aircraft records are up to date,
                    accurate and always available wherever you need them.
                  </p>
                  <p className={`${styles.description} ${styles.quote}`}>
                    “Using the TrustFlight Tech Log allows me to focus on the
                    flying instead of the paperwork. With a simple overview of
                    aircraft status, I can brief ahead of even arriving at the
                    aircraft, and features such as the built-in MEL and
                    electronic maintenance sign offs are superb. All of this
                    makes my life much easier and reduces the risk of delays due
                    to paperwork.”
                  </p>
                  <p className={`${styles.description} ${styles.name}`}>
                    Chris Forster <span>Pilot</span>
                  </p>
                </ScrollAnimation>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <GradientBackground>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col md={{ span: 5 }} className={pageStyles.coliPad}>
            <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
              <TextWrapper>
                <Title>Recognised by regulators globally</Title>
                <Text>The Tech Log is in use across a variety of operations within Europe and North America. Our collection of template documentation and experienced team make gaining regulatory acceptance of the system straightforward. We are also the only dedicated Electronic Tech Log provider to have gained acceptance by EASA through a letter of No Technical Objection (NTO) after extensive evaluation.</Text>
                <div>
                  <Text>Authorities we have worked with:</Text>
                  <TickWrapper>
                    <TickSection>{tick} EASA</TickSection>
                    <TickSection>{tick} United Kingdom</TickSection>
                    <TickSection>{tick} Isle of Man</TickSection>
                    <TickSection>{tick} Guernsey</TickSection>
                    <TickSection>{tick} Malta</TickSection>
                    <TickSection>{tick} Canada</TickSection>
                  </TickWrapper>
                </div>
              </TextWrapper>
            </ScrollAnimation>
          </Col>
          <Col md={5} className={`${pageStyles.coliPad}`}>
            <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <RegulatorImage>
                <img src={Regulators} alt="regulators" />
              </RegulatorImage>
            </ScrollAnimation>
          </Col>
        </Row>
      </GradientBackground>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Row className={styles.titleRow}>
              <Col md={12}>
                <CenteredDiv>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <h3 className={styles.title}>
                      Powerful Operations Support
                    </h3>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
              <Col md={{ span: 8, offset: 2 }}>
                <CenteredDiv>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <h4 className={styles.subTitle}>
                      From one aircraft to hundreds, our tools will aid in your
                      operations and provide the information you need, when you
                      need it.
                    </h4>
                  </ScrollAnimation>
                </CenteredDiv>
              </Col>
            </Row>
            <Row className={styles.cardsHolder}>{cards}</Row>
          </Col>
        </Row>
      </Container>
      <GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col md={12}>
              <Row className={styles.titleRow}>
                <Col md={12}>
                  <CenteredDiv>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInDown"
                      offset={0}
                    >
                      <h3 className={styles.title}>
                        Designed with pilots and operators. <br />
                        Continuously evolving.
                      </h3>
                    </ScrollAnimation>
                  </CenteredDiv>
                </Col>
                <Col md={{ span: 8, offset: 2 }}>
                  <CenteredDiv>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInUp"
                      offset={0}
                    >
                      <h4 className={styles.subTitle}>
                        Our Tech Log has been designed in close cooperation with
                        pilots, engineers and airlines ensuring that it meets
                        the needs of today’s operators. We never stop improving
                        though and are constantly making new features available
                        to our customers.
                      </h4>
                    </ScrollAnimation>
                  </CenteredDiv>
                </Col>
              </Row>
              <Row className={styles.benefitsRow}>
                <Col
                  lg={{ span: 4, offset: 1 }}
                  md={{ span: 5, offset: 1 }}
                  className={pageStyles.coliPad}
                >
                  <div className={styles.tickParent}>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInLeft"
                      offset={0}
                    >
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Fully digital Tech Log recording with journey and
                          flight logs
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Automated data import from popular flight planning,
                          scheduling and maintenance systems
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Full maintenance status and forecast</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Receipt scanning and cost tracking built right in
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>Digital MELs to quickly record defects</span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>
                          Fully offline capable so your data is always available
                        </span>
                      </div>
                      <div className={styles.tickWrapper}>
                        {tick}
                        <span>EASA, FAA, and Transport Canada compliant. Suitable for both private and commercial operations</span>
                      </div>
                    </ScrollAnimation>
                  </div>
                </Col>
                <Col lg={7} md={6} className={pageStyles.coliPad}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInRight"
                    offset={0}
                  >
                    <div
                      ref={postflightAnimationRef}
                      className={styles.screenImg}
                    />
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <Seamless
              title="Seamless Integrations"
              text="We’ve worked hard to integrate our Tech Log with the most popular services to ensure that you only have to enter information once. Data such as flight plans and maintenance requirements are brought in, with utilisation, defects and maintenance sign offs sent out seamlessly."
              cards={seamlessCards}
              externalAddMore
            />
          </Col>
        </Row>
        <Started />
      </Container>
      <AlsoInterestedIn page="tech" />
    </Layout>
  );
};

export const query = graphql`
  query TechLogQuery {
    mainImage: file(relativePath: { eq: "Tech-Log/tech-log-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paperFreeImg: file(relativePath: { eq: "Tech-Log/tech-log-screen-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    benefitsImg: file(relativePath: { eq: "Tech-Log/tech-log-screen-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
