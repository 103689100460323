import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./seamlessIntegration.module.less";
import ScrollAnimation from 'react-animate-on-scroll';

const TitleSpan = styled.h4`
  display: flex;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: left;
  color: #242d41;
  padding-top: 140px;
  padding-bottom: 20px;
  line-height: 1.57;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
    padding-top: 40px;
  }
  @media screen and (max-width: 991px) {
    font-size: 22px;
    padding-top: 30px;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    padding-top: 0px;
  }
`;

const TextSpan = styled.span`
  font-size: 16px;
  letter-spacing: 0.02px;
  text-align: center;
  color: #242d41;
  line-height: 1.63;
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01), 0 1px 20px 0 rgba(0, 0, 0, 0.15);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  @media screen and (max-width: 991px) {
    padding: 30px 15px;
  }
  @media screen and (max-width: 768px) {
    padding: 60px 15px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TitleText = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
`;

const ImageWrapper = styled.div`
  width: 80%;
  padding-bottom: 20px;
  text-align: center;
  @media screen and (max-width: 991px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
  }
`;

const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  align-items: flex-end;
  @media screen and (max-width: 576px) {
    padding-top: 10px;
    align-items: center;
  }
`;

const SecondDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    align-items: center;
  }
`;

const BottomSpan = styled.span`
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #242d41;
  @media screen and (max-width: 576px) {
    padding-left: 0px;
  }
`;

export default props => {
  let mobileAddMore = false;
  const CompanyCard = card => {
    if (card.title === "And more") {
      mobileAddMore = true;
    }
    return (
      card && (
        <div className={`${styles.seamlessCard} ${card.title === "And more" && styles.moreCard}`}>
          <CardWrapper>
            <StyledCard>
              {card.img && (
                <ImageWrapper>
                  <img src={card.img} alt={card.title} />
                </ImageWrapper>
              )}
              <TextWrapper>
                <TitleText>{card.title}</TitleText>
              </TextWrapper>
            </StyledCard>
          </CardWrapper>
        </div>
      )
    );
  };
  return (
    <Container>
      <Row className={styles.seamlessRow}>
        <Col md={3} sm={6} className={styles.coliPadHalf}>
          <FirstDiv>
          <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
            {CompanyCard(props.cards && props.cards[0])}
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} delay={100}>
            {CompanyCard(props.cards && props.cards[1])}
            </ScrollAnimation>
          </FirstDiv>
        </Col>
        <Col md={3} sm={6} className={styles.coliPadHalf}>
          <SecondDiv>
          <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} delay={200}>
            {CompanyCard(props.cards && props.cards[2])}
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} delay={300}>
            {CompanyCard(props.cards && props.cards[3])}
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} delay={350}>
            {props.externalAddMore && <BottomSpan>And more</BottomSpan>}
            {mobileAddMore && (
              <BottomSpan className={styles.mobileAddMore}>And more</BottomSpan>
            )}
            </ScrollAnimation>
          </SecondDiv>
        </Col>
        <Col md={{ span: 5, offset: 1 }} className={styles.coliPad}>
        <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
          <TitleSpan dangerouslySetInnerHTML={{ __html: props.title }} />
          <TextSpan>{props.text}</TextSpan>
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  );
};
