import React from "react";
import styled from "styled-components";
import Logbook from "../../assets/product-digital-engine-logbook.svg";
import Tech from "../../assets/product-tech-log.svg";
import Task from "../../assets/product-task-cards.svg";
import { Container, Row, Col } from "react-bootstrap";
import DisplayCard from "../LandingPage/displayCard";
import styles from "./alsoInterestedIn.module.less";
import pageStyles from "../../pages/page.module.less";
import ScrollAnimation from "react-animate-on-scroll";

const TechCard = {
  img: Tech,
  title: "Electronic Tech Log",
  text:
    "Automated flight entry, intuitive defect recording and fully integrated with your existing scheduling and maintenance systems. Gain a real-time view of your aircraft's status and utilisation",
  link: "techlog",
};

const LogbookCard = {
  img: Logbook,
  title: "Digital Engine Log",
  text:
    "The single-source of trusted flight and maintenance data associated with your aircraft. Standardised, secure and controlled by you, we make it easy to share data with the systems and stakeholders that require it.",
  link: "logbook",
};

const TaskCard = {
  img: Task,
  title: "Electronic Task Cards",
  text:
    "Conduct maintenance the modern way with real-time analytics, interactive manuals and records created automatically. Avoid spending hours writing up tasks and signing off paperwork.",
  link: "taskcards",
};

const StyledTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  margin-top: 40px;
  padding-left: 12px;
  margin-bottom: 40px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
    margin-top: 10px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
    padding-left: 0;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 60px;
  position: relative;
  @media screen and (max-width: 768px) {
    padding-bottom: 45px;
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 30px;
  }
`;

const SkewedBg = styled.div`
  background-image: linear-gradient(to top, #f8fcff 0%, #f0f5f9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  clip-path: polygon(0% 70%, 100% 33%, 100% 100%, 0% 100%);
  @media screen and (max-width: 768px) {
    background: #fff;
  }
`;

export default ({ page }) => {
  let cardsArray = [];
  switch (page) {
    case "tech":
      cardsArray = [TaskCard, LogbookCard];
      break;
    case "task":
      cardsArray = [TechCard, LogbookCard];
      break;
    case "logbook":
      cardsArray = [TechCard, TaskCard];
      break;
    case "registry":
      cardsArray = [TechCard, LogbookCard];
      break;
    case "camo":
      cardsArray = [TechCard, LogbookCard];
      break;
    case "mel":
      cardsArray = [TechCard, LogbookCard];
      break;
    default:
      cardsArray = [];
      break;
  }

  const cards = cardsArray.map((card, key) => (
    <Col md={6} className={styles.cardCol}>
      <ScrollAnimation
        animateOnce
        animateIn="fadeIn"
        offset={0}
        delay={key * 150}
      >
        <DisplayCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
          link={card.link}
          bottomPage
        />
      </ScrollAnimation>
    </Col>
  ));
  return (
    <Wrapper>
      <SkewedBg />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
        >
          <Col lg={10} md={12}>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
              <StyledTitle>You may also be interested in</StyledTitle>
            </ScrollAnimation>
          </Col>
          <Col lg={10} md={12}>
            <Row>{cards}</Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
